// Converts a px amount to rem
@function toRem($pxAmount) {
    @return ($pxAmount/16) * 1rem;
}

$questrial: 'Questrial', sans-serif;
$headerHeight: 6rem; 
$primaryTextColor: #444444;
$skyPinkColor: #f0e6e7;
$onHoverBackgroundColor: #dddddd40;
$onHoverTextColor: #222222;

$sectionMarginBottom: toRem(150);
$projCardWidth: toRem(256);
$projCardMargin: toRem(16);
$transitionTimingCard: .2s;
$transitionTimingMenu: .3s;

$size_h1: toRem(50);
$size_h2: toRem(40);
$size_h3: toRem(30);
$size_h4: toRem(22);
$size_h5: toRem(18);
$size_h6: toRem(14);
$size_p: toRem(18);

@media only screen and  (min-width: 769px) {
    $size_h1: toRem(50);
    $size_h2: toRem(20);
    $size_p: toRem(16);    
}

@media only screen and  (max-width: 768px) {
    $size_h1: toRem(48);
    $size_h2: toRem(18);
    $size_p: toRem(14);
}

@media only screen and  (max-width: 480px) {
    $size_h1: toRem(42);
    $size_h2: toRem(14);
    $size_p: toRem(10);
}

@media only screen and (max-width: 200px) {
    $size_h1: toRem(36);
    $size_h2: toRem(10);
    $size_p: toRem(6);
}

// *:not(html) {
//     -webkit-transform: translate3d(0, 0, 0);
// }


* { box-sizing: border-box; }

img { -ms-interpolation-mode: bicubic; }

button, a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

div.app {
    width: 100%;
    max-width: toRem(2560);
    margin: auto;
}

h1 {
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: $size_h1;
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

h2 {
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: $size_h2; 
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

h3 {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 600;
    font-size: $size_h3; 
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

h4 {
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: $size_h4; 
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

h5 {
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: $size_h5; 
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

h6 {
    font-family: 'Zilla Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: $size_h6; 
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}

p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: $size_p;
    line-height: normal;

    white-space: normal;
    -ms-word-wrap: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    word-break: normal;

    color: $primaryTextColor;
}     

header {
    $divLeft: 8rem;
    $marginWidth: 1rem;

    position: fixed;
    top: 0;
    width: 100%;
    height: $headerHeight;
    z-index: 5;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease-in-out;

    pointer-events: none;

    &.hidden {
        transform: translateY(-100%);
        pointer-events: none;
    }
    // overflow: hidden;
    // -webkit-filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.10));
    // -moz-filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.10));
    // -ms-filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.10));
    // -o-filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.10));
    // filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.10));
    // box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.10);
    // background-color: #ffffff;
    // backdrop-filter: blur(1px);

    button {
        width: $headerHeight;
        height: 100%;
        padding: 0;

        border: none;
        background-color: transparent;

        cursor: pointer;
        pointer-events: auto;

        img {
            width: toRem(48);
            max-width: 100%;

            &.logo {
                width: toRem(48);
                height: auto;
            }  

            &.navIcon {
                width: toRem(40);
                max-width: 100%;
            }

        }

        .toolTip {
            position: absolute;
            top: 35%;
            left: toRem(85);
            z-index: 1;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: toRem(13);
            color: $primaryTextColor;

            opacity: 0;
            transition: opacity ease-out $transitionTimingCard;
        }

        &:hover .toolTip {
            opacity: 1;
        }
    }

    .left {
        width: $headerHeight;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-left: $marginWidth;
        
        a {
            width: 100%;
            height: 100%;
        }
    }

    .right {
        width: auto;
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;

        margin-right: $marginWidth;

        .buttons {
            width: auto;
            height: 100%;
            margin-right: $marginWidth;

            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            align-items: center;
            gap: toRem(16);

            .buttonNav {
                width: auto;
                height: toRem(48);
                margin: 0 toRem(16);
                padding: 0 toRem(16);
              
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-size: $size_h5;
                line-height: $headerHeight;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: toRem(8);

                color: $primaryTextColor;
                // background-color: #ffffff; 
                // backdrop-filter: blur(1px);

                -webkit-filter: drop-shadow(0px 0px 0px rgba(87, 87, 87, 0.15));
                -moz-filter: drop-shadow(0px 0px 0px rgba(87, 87, 87, 0.15));
                -ms-filter: drop-shadow(0px 0px 0px rgba(87, 87, 87, 0.15));
                -o-filter: drop-shadow(0px 0px 0px rgba(87, 87, 87, 0.15));
                filter: drop-shadow(0px 0px 0px rgba(87, 87, 87, 0.15));

                cursor: pointer;
                pointer-events: auto;

                transition: all 0.1s ease-out;

                &:hover {
                    margin-bottom: 4px;
                    // margin-right: 4px;
                    // background-color: #ffffff;
                    color: #888888;
                    // filter: drop-shadow(6px 6px 0px rgba(187, 187, 187, 0.6));
                }
            }

            a {
                text-decoration: none;
            }
        }
    }
}

div.menuOut {
    width: 60vw;
    left: 120%;    
    transition: all cubic-bezier(0.5, 0, 0.75, 0) $transitionTimingMenu;
    
    div.outside {
        display: none;
    }
}

div.menuIn {
    width: 100vw;
    left: 0;
    transition: all cubic-bezier(0.25, 1, 0.5, 1) $transitionTimingMenu;

    div.outside {
        display: flex;
    }
}

div.menu {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;

    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    div.outside {
        height: 100%;
        flex-grow: 4;

        button {
            width: 100%;
            height: 100%;

            border: none;
            background-color: transparent;
        }
    }

    div.inside {
        $divLeft: 5rem;
        $marginWidth: 2rem;

        width: 60%;
        min-width: toRem(192);
        max-width: toRem(300);
        height: 100%;

        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;

        overflow: hidden;
        -webkit-filter: drop-shadow(-1px 1px 24px rgba(0, 0, 0, 0.10));
        -moz-filter: drop-shadow(-1px 1px 24px rgba(0, 0, 0, 0.10));
        -ms-filter: drop-shadow(-1px 1px 24px rgba(0, 0, 0, 0.10));
        -o-filter: drop-shadow(-1px 1px 24px rgba(0, 0, 0, 0.10));
        filter: drop-shadow(-1px 1px 24px rgba(0, 0, 0, 0.10));
        box-shadow: -1px 1px 24px rgba(0, 0, 0, 0.10);
        background-color: #ffffff;

        div.buttonClose {
            width: 50%;
            height: $headerHeight;
            margin-bottom: 15%;
            margin-right: $marginWidth;

            display: flex;
            flex-flow: row;
            flex-shrink: 0;
            justify-content: flex-end;
            align-items: center;
            align-self: flex-end;

            button {
                width: $divLeft;
                height: $divLeft;

                border: none;
                background-color: transparent;

                cursor: pointer;

                img.navIcon {
                    width: toRem(40);
                    max-width: 100%;
                }
            }

        }

        div.buttons {
            $buttonHeight: toRem(75);

            width: 100%;
            height: $buttonHeight*4;
            margin-bottom: 15%;

            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;

            .buttonNav {
                width: 100%;
                height: $buttonHeight;
                padding-left: toRem(30);
            
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: $size_h5;
                line-height: $headerHeight;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                border: none;
                color: $primaryTextColor;
                background-color: transparent;  

                cursor: pointer;
            }

            a {
                text-decoration: none;
            }
        }
    }
}



div.contact {
    position: fixed;
    top: 35vh;
    left: 2.5%;
    height: 13rem;
    z-index: 5;

    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    div.button:hover {
        // width: toRem(55);
        img {
            -webkit-transform: scale(1.375, 1.375);
            -moz-transform: scale(1.375, 1.375);
            -ms-transform: scale(1.375, 1.375);
            -o-transform: scale(1.375, 1.375);
            transform: scale(1.375, 1.375);
        }
        .toolTip {
            visibility: visible;
            opacity: 1;
        }
    }
    
    div.button {
        position: relative;
        width: toRem(40);
        height: toRem(40);
        margin: toRem(5);
        margin-left: 0;

        overflow: visible;
        // transition: all ease-out $transitionTimingCard;
        
        a {
            position: relative;
            width: 100%;
            height: 100%;

            display: flex;

            justify-content: center;
            align-items: center;

            flex-shrink: 0;
            border-radius: toRem(100);
            border: none;
            color: $primaryTextColor;
            background-color: #ffffffff;     
            z-index: 3;       

            cursor: pointer;

            img {
                width: 45%;
                max-width: 45%;
                height: auto;
                opacity: 1;

                // -webkit-transition: -webkit-transform .15s ease-out;
                // -moz-transition: -moz-transform .15s ease-out;
                // -ms-transition: -ms-transform .15s ease-out;
                // -o-transition: -o-transform .15s ease-out;
                transition: transform .15s ease-out;
            }
        }  

        .toolTip {
            position: absolute;
            height: 100%;
            padding: toRem(10);
            padding-left: toRem(55);
            padding-right: toRem(15);
            top: 0;
            left: toRem(0);

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: toRem(13);

            white-space: nowrap;
            border-radius: toRem(40);
            color: $primaryTextColor;
            background-color: #ffffff;
            opacity: 0;
            visibility: hidden;
            transition: opacity ease-out $transitionTimingCard;
        }
    }
}





div.content {
    position: relative;
    width: 100%;

    @media screen and (min-width: 2560px){
        width: 70%;
        margin: auto;
        overflow-x: visible;
    }

    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-self: center;

    background-color: #ffffff;
    overflow-y: clip;

    .anchor {
        position: absolute;
        top: toRem(-80);
    }

    div.wrapper {
        position: relative;
        width: 75%;
        margin: auto;
        margin-bottom: $sectionMarginBottom;

        .titleUnderscore {
            position: relative;
            width: 3rem;
            height: .2rem;

            margin-top: toRem(-25);
            margin-bottom: toRem(30);

            background-color: $primaryTextColor;
        }

        h3 {
            position: relative;
            width: 100%;
        }

        h4 {
            position: relative;
            width: 100%;
            margin-top: toRem(0);
        }
    }

    div.work {
        position: relative;
        width: 100%;
        margin: auto;

        .titleUnderscore {
            position: relative;
            width: 3rem;
            height: .2rem;

            margin-top: toRem(-25);
            margin-bottom: toRem(30);
            margin-left: 12.5%;

            background-color: $primaryTextColor;;
        }

        h3 {
            margin-left: 12.5%;
        }

        h3.heading {
            position: relative;
            width: 75%;
            margin-top: toRem(0);
            margin-left: 12.5%;
            margin-bottom: toRem(16);
            
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: toRem(36);    

            @media screen and (min-width: 768px){
                font-size: toRem(48);   
            }
        }

        h4 {
            position: relative;
            width: 75%;
            margin-top: toRem(0);
            margin-left: 12.5%;
            
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: toRem(24);    
        }
    
        div.cardsDesktop {
            position: relative;
            width: 75%;
            margin: auto;
            margin-bottom: $sectionMarginBottom;
            padding-top: toRem(16);
            padding-bottom: toRem(16);

            display: flex;
            flex-flow: row wrap;
            // justify-content: center;
            justify-content: space-between;
            align-items: flex-start;
            column-gap: 5%;
            row-gap: toRem(64);
        
            overflow: visible;

            .lazyload-wrapper  {
                width: 100%;
                max-width: 100%;
                display: flex;    
                flex-flow: row wrap;
                justify-content: center;
                align-items: flex-start;
            }
        }

        div.cardsMobile {
            position: relative;
            width: 100%;//87.5%;
            padding-left: 12.75%;
            padding-top: toRem(16);
            padding-bottom: toRem(16);
            margin-bottom: $sectionMarginBottom;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            column-gap: toRem(40);
        
            overflow-x: scroll;

            .lazyload-wrapper  {
                // width: 100%;
                max-width: 100%;
                display: flex;    
                flex-flow: row wrap;
                justify-content: center;
                align-items: flex-start;
            }

            .lazyload-wrapper:first-child {
                div.cardMobile {
                    margin-left: 0;
                }
            }
        }

        div.cardDesktop {
            position: relative;
            width: 100%;
            max-width: 47.5%;
            // min-width: toRem(500);
            // height: toRem(500);
            // max-height: toRem(500);

            display: flex;
            flex-flow: column;
            flex-shrink: 0;
            justify-content: flex-start;
            align-items: flex-start;
            align-self: flex-start;
            // overflow: visible; 
    
            border-radius: toRem(16);
            // border-left: 4px solid;
            // border-top: 4px solid;
            // border-color: $primaryTextColor;
            // background-color: #ffffff;
            // -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
            // -moz-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
            // -ms-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
            // -o-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
            // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

            // box-shadow: 8px 8px 0px rgba(187, 187, 187, 1);
    
            div.imageHolder {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: toRem(448);
                padding: toRem(4);
                padding-top: toRem(16);
    
                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: toRem(16);
                -webkit-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -moz-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -ms-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -o-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                // filter: drop-shadow(0px 0px 0px rgba(187, 187, 187, 0));
                // box-shadow: 0px 0px 4px rgba(87, 87, 87, 0.1);
                transition: filter $transitionTimingCard ease-in-out, 
                    top $transitionTimingCard ease-out,
                    left $transitionTimingCard ease-out,
                    width $transitionTimingCard ease-out,
                    padding $transitionTimingCard ease-out;

                background-color: #bbbbbb;
                cursor: pointer;
    
                .lazyload-wrapper  {
                    width: 100%;
                    height: 100%;
                    display: flex;    
                    justify-content: center;
                    align-items: center;

                    a {
                        // position: relative;
                        width:100%;
                        height: 100%;        
                        display: flex;    
                        justify-content: center;
                        align-items: center;

                        img {
                            // position: relative;
                            width: 100%;
                            height: auto;
                        }
                    }

                    img {
                        // position: relative;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            div.descWrapper {
                // position: absolute;
                width: 100%;
                max-width: 100%;
                // padding: toRem(24) 8%;
                margin-top: toRem(448);
                padding-bottom: toRem(32);
                padding-top: toRem(16);
    
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                align-items: center;
                // align-self: flex-end;

                // background-color: #ffffff;

                // -webkit-filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.10));
                // -moz-filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.10));
                // -ms-filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.10));
                // -o-filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.10));
                // filter: drop-shadow(0px -1px 14px rgba(0, 0, 0, 0.10));
                // box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.10);

                // -webkit-transition: height .2s ease-out;
                // -moz-transition: height .2s ease-out;
                // -ms-transition: height .2s ease-out;
                // -o-transition: height .2s ease-out;
                // transition: height .2s ease-out;

                div.topRow {
                    position: relative;
                    width: 100%;
                    height: toRem(40);

                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    align-items: flex-end;

                    // a {
                    //     width: 100%;
                    //     text-decoration: none;
                    // }

                    h4.cardTitle {
                        width: 80%;
                        margin: 0;

                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
        
                    // div.buttons {
                    //     position: relative;
                    //     width: auto;
                    //     height: auto;

                    //     display: flex;
                    //     flex-flow: row nowrap;
                    //     justify-content: flex-end;
                    //     align-items: flex-end;
                    //     column-gap: toRem(16);

                    //     a:hover {
                    //         img {
                    //             -webkit-transform: scale(1.25, 1.25);
                    //             -moz-transform: scale(1.25, 1.25);
                    //             -ms-transform: scale(1.25, 1.25);
                    //             -o-transform: scale(1.25, 1.25);
                    //             transform: scale(1.25, 1.25);
                    //         }
                    //         .toolTip {
                    //             opacity: 1;
                    //         }
                    //     }

                    //     a:only-child {
                    //         margin-left: auto;
                    //     }

                    //     a {
                    //         position: relative;
                    //         width: toRem(40);
                    //         height: toRem(40);
                    //         // margin-left: toRem(16);

                    //         border-radius: toRem(100);
                    //         border: none;
                    //         display: flex;

                    //         font-family: 'Zilla Slab', sans-serif;
                    //         font-style: normal;
                    //         font-weight: 700;
                    //         font-size: $size_h1;
                    //         color: $primaryTextColor;
                    //         background-color: transparent;

                    //         cursor: pointer;

                    //         img {
                    //             position: relative;
                    //             width: auto;
                    //             height: toRem(32);
                    //             margin: auto;

                    //             display: flex;
                    //             align-self: center;

                    //             -webkit-filter: contrast(.8);
                    //             -moz-filter: contrast(.8);
                    //             -ms-filter: contrast(.8);
                    //             -o-filter: contrast(.8);
                    //             filter: contrast(.8);
                    //             transition: all ease-out .1s;
                    //         }

                    //         .toolTip {
                    //             $width: toRem(120);

                    //             position: absolute;
                    //             width: $width;
                    //             height: 100%;
                    //             bottom: toRem(20);
                    //             left: 50%;
                    //             margin-left: -$width/2;
                    
                    //             font-family: 'Poppins';
                    //             font-style: normal;
                    //             font-weight: 500;
                    //             font-size: toRem(13);
                            
                    //             text-align: center;
                    //             border-radius: toRem(40);
                    //             color: $primaryTextColor;
                    //             background-color: transparent;
                    //             opacity: 0;
                    //             // display: none;
                    //             transition: opacity ease-out $transitionTimingCard;
                    //         }
                    //     }
                    // }
                }

                div.botRow {
                    position: relative;
                    width: 100%;
                    height: auto;
                    margin-top: toRem(16);

                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-content: flex-start;
                    row-gap: toRem(16);

                    div.tags {
                        position: relative;
                        width: 100%;
                        margin-left: auto;
                        
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: flex-start;
                        align-content: flex-start;
                        align-items: flex-start;
            
                        div.tagWrapper {
                            border-radius: toRem(16);
                            border: toRem(2) solid #cccccc;
                            padding: toRem(2) toRem(8);
                            margin-right: toRem(8);
                        }

                        h6 {
                            margin: 0;
                            // padding-right: toRem(10);
            
                            font-family: 'DM Sans';
                            // font-family: 'Host Grotesk';
                            // font-family: 'Poppins', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;

                            color: #cccccc;
                        }
                    }  

                    p {
                        width: 100%;
                        margin: 0;
    
                        font-family: 'Host Grotesk';
                        font-size: toRem(17);
                        line-height: 150%;

                        @media screen and (min-width: 768px){
                            font-size: toRem(19);          
                        }
                    }
                }          
            }

            a {
                text-decoration: none;
            }

            &:last-child {
                margin-left: auto;
                margin-right: auto;
            }

            &:hover div.imageHolder {
                top: toRem(-8);
                left: toRem(-8);
                width: calc(100% + #{toRem(8)});
                padding-left: toRem(4);
                padding-right: toRem(4);
                // filter: drop-shadow(0px 4px 12px rgba(87, 87, 87, 0.2));
                filter: drop-shadow(10px 10px 0px rgba(187, 187, 187, 0.6));
            }
        }

        div.wide {
            max-width: none;

            div.imageHolder div.lazyload-wrapper img {
                width: 88%;
            }
        }


        div.cardMobile {
            position: relative;
            width: $projCardWidth;
            // margin: $projCardMargin;

            display: flex;
            flex-flow: column;
            flex-shrink: 0;
            justify-content: flex-start;
            align-items: flex-start;
            align-self: stretch;
            overflow: visible;
    
            border-radius: toRem(16);
            border: none;
            background-color: #ffffff;
            // -webkit-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            // -moz-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            // -ms-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            // -o-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            // filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            // box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);

            // transition: all ease-in-out $transitionTimingCard;
    
            div.imageHolder {
                width: 100%;
                height: $projCardWidth;
                max-height: $projCardWidth;
    
                background-color: #bbbbbb;
                border-radius: toRem(16);
                // overflow: hidden;
                
                -webkit-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -moz-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -ms-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                -o-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
                filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));

                .lazyload-wrapper  {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: flex;    
                    justify-content: center;
                    align-items: center;

                    img {
                        position: relative;
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                        max-height: $projCardWidth;
                        border-radius: toRem(16);
                    }
                }    
            }
    
            div.text {
                width: $projCardWidth;
                max-width: 100%;
                height: 100%;
                padding: toRem(20);
                padding-bottom: toRem(10);
                padding-left: 0;
                padding-right: 0;
                background-color: #ffffff;
    
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
    
                h4.cardTitle {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: toRem(8);
                    margin-left: 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: toRem(18);
                }
    
                p {
                    max-width: 100%;
                    margin-top: toRem(0);
                    margin-bottom: toRem(16);
                    font-size: toRem(15);
                    // font-size: $size_p;
                    font-family: 'Host Grotesk';
                    line-height: 150%;
                }          
            }
    
            div.tags {
                position: relative;
                width: 100%;
                bottom: 0;
                
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: toRem(8);

                div.tagWrapper {
                    border-radius: toRem(16);
                    border: toRem(2) solid #cccccc;
                    padding: toRem(2) toRem(8);
                    margin-right: toRem(8);
                    margin-bottom: toRem(8);
                }

                h6 {
                    margin: toRem(0);
                    // padding-right: toRem(10);
    
                    font-family: 'DM Sans';
                    // font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: toRem(12);
                }
            }  

            div.buttons {
                position: relative;
                width: 100%;
                height: toRem(40);
                margin-top: auto;
                margin-bottom: toRem(5);
                margin-left: auto;

                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: stretch;
                column-gap: toRem(24);

                a:only-child {
                    margin-left: auto;
                }

                a {
                    position: relative;
                    width: toRem(40);
                    height: toRem(40);

                    border-radius: toRem(100);
                    border: none;
                    display: flex;

                    font-family: 'Zilla Slab', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: $size_h1;
                    color: $primaryTextColor;
                    background-color: transparent;

                    cursor: pointer;

                    img {
                        position: relative;
                        width: auto;
                        height: toRem(32);
                        margin: auto;

                        display: flex;
                        align-self: center;

                        -webkit-filter: contrast(.8);
                        -moz-filter: contrast(.8);
                        -ms-filter: contrast(.8);
                        -o-filter: contrast(.8);
                        filter: contrast(.8);
                    }

                    .toolTip {
                        $width: toRem(150);

                        position: absolute;
                        width: $width;
                        height: 100%;
                        bottom: toRem(20);
                        left: 50%;
                        margin-left: -$width/2;
            
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: toRem(13);
                    
                        text-align: center;
                        border-radius: toRem(40);
                        color: $primaryTextColor;
                        background-color: transparent;
                        opacity: 0;
                    }
                }
            }
        }

        div.cardLast {
            width: $projCardMargin;
            margin: $projCardMargin;
            flex-shrink: 0;
        }

    }

    img.banner {
        width: 100%;
        -webkit-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.10));
        -moz-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.10));
        -ms-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.10));
        -o-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.10));
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.10));
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.10);
    }

    div.pages {
        position: relative;
        width: 80%;
        margin: auto;
        margin-top: 3rem;

        @media screen and (max-width: 767px){
            overflow-x: hidden;
        }

        @media screen and (min-width: 768px){
            width: 70%;
        }

        @media screen and (min-width: 1280px){
            width: 50%;
        }


        // margin-bottom: $sectionMarginBottom;

        // position: relative;
        // width: 100%;
        // margin: auto;
        // margin-top: toRem(48);
        // padding-left: 12.5%;
        // padding-right: 12.5%;

        // @media screen and (min-width: 768px){
        //     padding-left: 25%;
        //     padding-right: 25%;
        // }
        // margin-bottom: $sectionMarginBottom;
        
        .titleUnderscore {
            position: relative;
            width: toRem(64);
            height: toRem(6);
            border-radius: toRem(16);

            margin-top: toRem(-40);
            margin-bottom: toRem(32);

            background-color: $primaryTextColor;
        }

        div.contentsTable {
            position: sticky;
            top: 0;
            padding-top: toRem(184);
            margin-left: calc(100% + 96px);     // px is space between
            // margin-bottom: toRem(-228);
            z-index: 2;
            transition: opacity $transitionTimingCard ease-in-out;

            &.fade-out {
                opacity: 0;
                pointer-events: none;
            }
            
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            // Style 1 - Vertical line
            a {
                display: flex;
                margin: toRem(4);
                padding: toRem(8);
                // padding-right: toRem(20);   // Should be equal to the width and margin-right of .line
                min-width: fit-content;
                height: toRem(48);
                align-items: center;

                font-family: 'Host Grotesk';
                font-size: toRem(19);
                font-weight: 500;
                text-decoration: none;
                color: $primaryTextColor;
                white-space: nowrap;

                border-radius: toRem(8);
            
                &:hover {
                    background-color: $onHoverBackgroundColor;
                    color: $onHoverTextColor;
                }

                &.current {
                    // background-color: #fafafa;
                    font-weight: 900;
                }

                .line {
                    height: toRem(0);
                    width: toRem(0);
                    margin-right: 0;
                    border-radius: toRem(2);
                    // background-color: #ffffff00;
                    transition: all $transitionTimingCard ease-in;

                    &.current {
                        height: toRem(32);
                        width: toRem(4);
                        padding: 0;
                        margin-right: toRem(8);
                    }
                }
            }
        }

        div.intro {
            position: relative;
            width: 100%;
            margin: toRem(10) auto;
            margin-bottom: toRem(50);

            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;

            div.col:first-of-type {
                width: 20%;
                min-width: toRem(100);
                margin-right: 10%;
            }

            div.col {
                width: 70%;
                min-width: toRem(100);
                h4 {
                    margin: 0;
                    margin-bottom: toRem(5);
                }

                .teamColumns {
                    display: flex;
                    flex-wrap: wrap;
                    // column-gap: toRem(16);

                    p {
                        width: 40%;
                        white-space: nowrap;
                        min-width: toRem(250);
                    }
                }
            }   

            div.buttons {
                position: relative;
                width: 100%;
                min-width: toRem(100);
                height: toRem(50);

                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                a:hover {
                    img {
                        // height: toRem(25);
                        -webkit-transform: scale(1.25, 1.25);
                        -moz-transform: scale(1.25, 1.25);
                        -ms-transform: scale(1.25, 1.25);
                        -o-transform: scale(1.25, 1.25);
                        transform: scale(1.25, 1.25);
                    }
                    .toolTip {
                        opacity: 1;
                    }
                }

                a:only-child {
                    // margin-left: auto;
                    margin-right: auto;
                }

                a {
                    position: relative;
                    width: toRem(50);
                    height: toRem(50);
                    margin-right: toRem(24);

                    border-radius: toRem(100);
                    border: none;
                    display: flex;

                    font-family: 'Zilla Slab', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: $size_h1;
                    color: $primaryTextColor;
                    background-color: transparent;

                    cursor: pointer;

                    img {
                        position: relative;
                        width: auto;
                        height: toRem(35);
                        margin: auto;

                        display: flex;
                        align-self: center;

                        -webkit-filter: contrast(.8);
                        -moz-filter: contrast(.8);
                        -ms-filter: contrast(.8);
                        -o-filter: contrast(.8);
                        filter: contrast(.8);
                        transition: all ease-out .1s;
                    }

                    .toolTip {
                        $width: toRem(120);

                        position: absolute;
                        width: $width;
                        height: 100%;
                        top: toRem(50);
                        left: 50%;
                        margin-left: -$width/2;
            
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: toRem(13);
                    
                        text-align: center;
                        border-radius: toRem(40);
                        color: $primaryTextColor;
                        background-color: transparent;
                        opacity: 0;
                        // display: none;
                        transition: opacity ease-out $transitionTimingCard;
                    }
                }
            }
        }

        div.section {
            position: relative;
            margin: toRem(16) auto;
            margin-bottom: toRem(196);

            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: flex-start;   
            scroll-margin-top: toRem(32);   // Buffer space for contentsTable scrollintoview links

            width: 100%;
            @media screen and (min-width: 768px){
                width: 100%;
            }
            
            div.imageHolder {
                position: relative;
                width: 100%;
                max-height: 100%;
                margin-top: toRem(30);
                margin-bottom: toRem(100);
                margin-left: auto;
                margin-right: auto;
    
                display: flex;
                justify-content: center;
                align-items: center;
    
                .lazyload-wrapper  {
                    width: 100%;
                    height: 100%;
                    display: flex;    
                    justify-content: center;
                    align-items: center;
    
                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: toRem(8);
                    }
                }

                img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: toRem(8);
                }
            }

            div.wide {
                margin-bottom: toRem(100);

                display: flex;
                flex-flow: row wrap;
                // column-gap: toRem(24);
                justify-content: space-between;

                a.okizemeLink {
                    max-width: 48%;
                }
            }

            div.narrow {
                margin-bottom: toRem(100);

                display: flex;
                flex-flow: column wrap;
            }

            div.imageGallery {
                width: 130%;    // The extra 30% is to make it bigger, and we offset it with margin-left
                margin-left: -15%;
                margin-bottom: toRem(32);
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: flex-start; 

                div.imageHolder {
                    width: 30%;
                    margin-left: toRem(8);
                    margin-right: toRem(8);
                    min-width: toRem(256);
                    max-width: toRem(336)
                }
            }
        
            a {
                text-decoration: none;
                border-radius: toRem(8);
                padding: 0 toRem(6);
                background-color: #eeeeee;
                color: $primaryTextColor;
                font-weight: 500;

                &:hover {
                    background-color: #dddddd;
                    transition: all $transitionTimingCard ease-in-out; 
                }
            }

            a.okizemeLink {
                margin-bottom: toRem(32);
                padding-bottom: toRem(8);
                max-width: 100%;

                display: flex;
                flex-flow: column;
                align-items: center;

                text-align: center;
                text-decoration: none;
                font-family: 'Host Grotesk', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: $size_p;
                line-height: normal;
            
                white-space: normal;
                -ms-word-wrap: normal;
                word-wrap: normal;
                -ms-word-break: normal;
                word-break: normal;
            
                background-color: #ffffff;
                color: $primaryTextColor;

                img {
                    top: 0;
                    left: 0;
                    padding: 0;
                    transition: all $transitionTimingCard ease-in-out; 
                }

                &:hover {
                    img {
                        top: toRem(-8);
                        left: toRem(-8);
                        width: calc(100% + #{toRem(8)});
                        padding-left: toRem(4);
                        padding-right: toRem(4);
                        // filter: drop-shadow(0px 4px 12px rgba(87, 87, 87, 0.2));
                        filter: drop-shadow(10px 10px 0px rgba(187, 187, 187, 0.6));
                    }
                }
            }
        }

        div.buttons {
            position: relative;
            width: toRem(100);
            min-width: toRem(100);
            height: toRem(50);

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            a:hover {
                img {
                    // height: toRem(25);
                    -webkit-transform: scale(1.25, 1.25);
                    -moz-transform: scale(1.25, 1.25);
                    -ms-transform: scale(1.25, 1.25);
                    -o-transform: scale(1.25, 1.25);
                    transform: scale(1.25, 1.25);
                }
                .toolTip {
                    opacity: 1;
                }
            }

            a:only-child {
                margin-right: auto;
            }

            a {
                position: relative;
                width: toRem(50);
                height: toRem(50);

                border-radius: toRem(100);
                border: none;
                display: flex;

                font-family: 'Host Grotesk', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: $size_h1;
                color: $primaryTextColor;
                background-color: transparent;

                cursor: pointer;

                img {
                    position: relative;
                    width: auto;
                    height: toRem(35);
                    margin: auto;

                    display: flex;
                    align-self: center;

                    -webkit-filter: contrast(.8);
                    -moz-filter: contrast(.8);
                    -ms-filter: contrast(.8);
                    -o-filter: contrast(.8);
                    filter: contrast(.8);
                    transition: all ease-out .1s;
                }

                .toolTip {
                    $width: toRem(120);

                    position: absolute;
                    width: $width;
                    height: 100%;
                    top: toRem(50);
                    left: 50%;
                    margin-left: -$width/2;
        
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: toRem(13);
                
                    text-align: center;
                    border-radius: toRem(40);
                    color: $primaryTextColor;
                    background-color: transparent;
                    opacity: 0;
                    // display: none;
                    transition: opacity ease-out $transitionTimingCard;
                }
            }
        }

        div.tags {
            position: relative;
            width: 100%;
            
            display: flex;
            flex-flow: row wrap;
            row-gap: toRem(8);
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: toRem(16);

            div.tagWrapper {
                border-radius: toRem(16);
                border: toRem(2) solid #cccccc;
                padding: toRem(2) toRem(8);
                margin-right: toRem(8);

                @media screen and (min-width: 768px){
                    margin-right: toRem(16);
                }
            }

            h6 {
                margin: 0;

                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 600;
                font-size: toRem(12);
                line-height: normal;
                white-space: nowrap;

                color: #cccccc;

                @media screen and (min-width: 768px){
                    font-size: toRem(16);
                }
            }
        }  
        
        .phrases {
            width: 100%;
            margin-top: toRem(0);
            margin-bottom: toRem(10);

            .phrase {
                display: flex;
                width: 90%;
                margin-bottom: toRem(10);

                flex-flow: row;
                justify-content: flex-start;
                align-items: flex-start;

                img {
                    margin: toRem(10) toRem(10);
                    width: toRem(8);
                    height: auto;
                }

                p {
                    margin: 0 toRem(10);    
                }
            }
        } 

        h3 {
            width: 100%;
            font-size: toRem(48);

            &.title {
                width: 100%;
                max-width: 100%;
                margin-top: 0;
                margin-bottom: toRem(30);
    
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: toRem(48);
    
                @media screen and (min-width: 768px){
                    font-size: toRem(64);          
                }
            }

            &.number {
                margin-top: 0;
                margin-bottom: toRem(8);
            }
        }

        h4 {
            margin-bottom: toRem(0);
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: toRem(20);

            &.hook {
                font-family: 'Zilla+Slab';
                font-weight: 400;
                font-style: italic;
                font-size: $size_h4;

                @media screen and (min-width: 768px){
                    font-size: toRem(40);          
                }
            }

            &.heading {
                margin: 0;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: $size_h4;    /* 22pt */
    
                @media screen and (min-width: 768px){
                    font-size: toRem(28);          
                }
            }   
    
            &.subheading {
                margin: 0;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: $size_h5;    /* 18pt */
    
                @media screen and (min-width: 768px){
                    font-size: toRem(20);          
                }
            }   
        }

        h5 {
            margin: toRem(3) 0;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
        }

        p {
            font-family: 'Host Grotesk';
            font-size: toRem(17);
            line-height: 150%;
            margin-top: toRem(5);
            width: 100%;

            @media screen and (min-width: 768px){
                font-size: toRem(19);          
            }

            @media screen and (min-width: 768px){
                width: 70%;
            }

            &.bold {
                font-weight: 600;
            }

            &.caption {
                width: 100%;
                font-size: toRem(16);
                font-weight: 400;
                color: #888888;
            }

            &.code {
                font-size: toRem(16);
                font-family: 'Courier New', Courier, monospace;
                font-weight: 400;
            }
        }

        ol {
            margin-top: 0;
            padding-inline-start: toRem(0);
            list-style: none;
            counter-reset: li-index;
            
            li {
                margin-bottom: toRem(8);
                counter-increment: li-index;
                
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-size: toRem(18);
                color: $primaryTextColor;
            }

            li::before {
                margin-right: toRem(8);
                content: counter(li-index) '.';
                font-weight: 600;
            }
        }

        ol.solutions {
            padding-inline-start: toRem(0);
            list-style: none;
            counter-reset: solution 2;

            li {
                margin-bottom: toRem(16);
                position: relative;
                display: inline-block;
            }

            li::before {
                margin-right: toRem(8);
                position: absolute;
                left: 0;
                top: 0;

                content: 'Solution ' counter(solution) ':';
                font-weight: 600;
            }

            li:nth-child(1)::before {
                content: 'Solution 1:';
            }

            li:nth-child(2), li:nth-child(3) {
                counter-increment: solution;
            }

            li span {
                display: inline-block;
                text-indent: toRem(0);
                margin-left: toRem(108);
            }
        }

        div.grid {
            display: flex;
            flex-flow: row wrap;
            justify-content: start;
            column-gap: 10%;
            row-gap: toRem(32);

            div.gridCell {
                width: 45%;
                min-width: toRem(256);
                margin: 0;

                p {
                    width: 100%;
                }    
            }
        }

        div.codeblock {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            
            padding: 16px;
            padding-left: 24px;
            padding-top: 24px;
            background-color: #f3f3f3;
            border-radius: 8px;
        }
    }
}

.footer {
    width: 100%;
    height: auto;
    padding: toRem(64) 0 toRem(96) 0;
    background-color: $skyPinkColor;

    // content div is only on the home page, so this only applies to home page
    .content & {
        background-color: #ffffff;
    }

    // Default for width > 1280
    .container {    
        // width: 50%;
        margin: auto;
        display: flex;
        // flex-flow: row;
        // justify-content: space-between;
        // align-items: flex-end;
        // gap: toRem(64);

        // Display > 768 but < 1280
        // &.medium {
        //     width: 70%;
        // }

        // Display <= 768
        width: 80%;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: toRem(40);

        @media screen and (min-width: 768px){    
            width: 70%;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-end;
            gap: toRem(64);
        }

        @media screen and (min-width: 1280px){    
            width: 50%;
        }

        .columns {
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;

            &:last-child {
                @media screen and (min-width: 768px){ 
                    justify-content: flex-end;
                }
            }

            .column {
                width: auto;
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                
                gap: toRem(24);

                // .item {
                //     display: flex;
                //     flex-flow: row;
                //     justify-content: center;
                // }
            }
        }

        h6 {
            margin: 0;
    
            font-family: 'Poppins';
            font-weight: 500;
            // font-size: '.875rem';
        }

        a {
            width: auto;
            text-decoration: none;
            color: $primaryTextColor;

            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: toRem(8);

            // border-radius: toRem(8);
            // padding: toRem(2) toRem(6);

            img {
                width: toRem(20);
                transition: margin $transitionTimingCard ease-in-out, transform $transitionTimingCard ease-out;
            }

            &:hover {
                // background-color: #eeeeee;

                h6 {
                    color: #bbbbbb;
                }

                img {
                    // margin-left: toRem(4);
                    transform: rotate(45deg);
                    filter: invert(75%) sepia(0%) saturate(253%) hue-rotate(140deg) brightness(95%) contrast(77%);
                }
            }
        }
    }    
}


.footerSeeAlso {
    position: relative;
    width: 100%;
    // margin-top: toRem(128);
    padding-top: toRem(128);
    padding-bottom: toRem(176);
    overflow: hidden;

    @media screen and (min-width: 768px){    
        padding-top: toRem(228);    
    }    
    
    @media screen and (min-width: 1280px){    
        padding-left: 25%;
        padding-right: 25%;      
    }
    
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    // background-color: #FAFAFA;
    background-color: $skyPinkColor;

    .title {
        margin-bottom: toRem(40);
        height: auto;
    }

    .container {
        margin: 0 auto;
        margin-top: 3rem;

        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: toRem(8);

        width: 80%;
    
        @media screen and (min-width: 768px){
            width: 70%;
        }
        
        @media screen and (min-width: 1280px){
            width: 100%;
        }
    }

    // .titleUnderscore {
    //     position: relative;
    //     width: toRem(64);
    //     height: toRem(6);
    //     border-radius: toRem(16);

    //     margin-top: toRem(-40);
    //     margin-bottom: toRem(32);

    //     background-color: $primaryTextColor;
    // }

    a {
        margin: toRem(32) 0;
        text-decoration: none;

        &:hover {
            img {
                margin-left: toRem(16);
            }

            div.underline {
                width: 100%;
            }
        }

        div {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-content: flex-start;
            column-gap: toRem(8);

            div.imageHolder {
                height: toRem(30);
                
                img {
                    width: toRem(20);
                    height: auto;

                    transition: margin $transitionTimingCard ease-in-out;
                }
            }
        }

        // div.underline {
        //     width: 0%;
        //     height: 4px;
        //     margin-top: toRem(16);
        //     border-radius: toRem(24);

        //     background-color: #555555;

        //     transition: width $transitionTimingCard ease-in-out, margin-bottom $transitionTimingCard ease-in-out;
        // }
    }

    h4 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: toRem(20);

        &.heading {
            margin: 0;
            margin-bottom: toRem(8);
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: $size_h4;    /* 22pt */

            @media screen and (min-width: 768px){
                font-size: toRem(28);          
            }
        }   

        &.subheading {
            margin: 0;
            margin-bottom: toRem(8);
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: $size_h5;    /* 18pt */

            @media screen and (min-width: 768px){
                font-size: toRem(20);          
            }
        }   
    }

    img.clouds {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 200%;

        @media screen and (min-width: 768px){    
            width: 100%;
        }
    }
}

button.topButton {
    position: fixed;
    bottom: toRem(32);
    right: toRem(32);
    width: toRem(48);
    height: toRem(48);

    @media screen and (min-width: 768px){
        bottom: toRem(48);
        right: toRem(48);
        width: toRem(64);
        height: toRem(64);
    }

    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: toRem(100);
    color: $primaryTextColor;
    cursor: pointer;
    outline: none;

    opacity: 1;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
    -moz-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
    -ms-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
    -o-filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
    filter: drop-shadow(0px 0px 4px rgba(87, 87, 87, 0.15));
    
    transition: 
        all $transitionTimingCard ease-in-out,
        filter $transitionTimingCard ease-in-out;

    img {
        width: toRem(24);
        transition: all 0.5s ease-out;

        @media screen and (min-width: 768px){
            width: toRem(32);
        }

        @keyframes imgAnim {
            50% {
                margin-top: toRem(24);
            }

            70% {
                margin-bottom: toRem(24);
            }

            100% {
                margin: 0;
            }
        }
    }

    &:hover {
        img {
            animation: imgAnim 0.5s ease-out;
        }
        
        @media screen and (min-width: 768px){
            bottom: toRem(52);
            right: toRem(52);
            width: toRem(64);
            height: toRem(64);
        }
        filter: drop-shadow(6px 6px 0px rgba(187, 187, 187, 0.6));
    }

    // &:hover div.imageHolder {
    //     width: calc(100% + #{toRem(8)});
    //     padding-left: toRem(4);
    //     padding-right: toRem(4);
    // }
}

button.hide.topButton{
    opacity: 0;
    z-index: 10;
}


// .sadButton {
//     width: auto;
//     height: auto;
//     padding: toRem(10) toRem(20);
//     margin-top: toRem(40);
//     margin-bottom: toRem(100);

//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: $size_h5;
//     line-height: normal;
//     align-self: center;

//     border: none;
//     border-radius: toRem(100);
//     color: $primaryTextColor;
//     background-color: #ffffff;  

//     -webkit-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
//     -moz-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
//     -ms-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
//     -o-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
//     filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
//     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
//     cursor: pointer;
// }

.parallax-outer {
    position: absolute;
    width: 100%;

    // .parallax-inner {
    //     width: 100%;
    // }

    img {
        width: 100%;
    }
}

div.background {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: toRem(50);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    div {
        position: absolute;
        bottom: 0;
        width: 100%;

        display: flex;
        flex: row;
        justify-content: center;
    }

    img {
        margin: auto;
        width: 430px;

        &.underlay {
            width: calc(1.8 * 640px);
        }

        &.overlay {
            width: calc(1.3 * 640px);
        }

        @media screen and (min-width: 430px){
            margin: auto;
            width: 640px;
        }

        @media screen and (min-width: 640px){
            margin: auto;
            width: 1280px;

            &.underlay {
                width: calc(1.8 * 1280px);
            }

            &.overlay {
                width: calc(1.3 * 1280px);
            }
        }

        @media screen and (min-width: 768px){
            margin: auto;
            width: 1600px;

            &.underlay {
                width: calc(1.8 * 1600px);
            }

            &.overlay {
                width: calc(1.3 * 1600px);
            }
        }
        
        @media screen and (min-width: 1280px){
            margin: auto;
            width: 1920px;       
            
            &.underlay {
                width: calc(1.8 * 1920px);
            }

            &.overlay {
                width: calc(1.3 * 1920px);
            }
        }

        @media screen and (min-width: 1921px){
            margin: auto;
            width: 2560px;            
            
            &.underlay {
                width: calc(1.8 * 2560px);
            }

            &.overlay {
                width: calc(1.3 * 2560px);
            }
        }
    }

    background-color: $skyPinkColor;


    div.intro {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-self: center;
        z-index: 4;
    
        background-color: transparent;
    
        div.text {
            position: relative;
            width: 60%;
            margin: auto;
            margin-top: 10rem;
    
            display: flex;
            flex-flow: column wrap;
            align-self: flex-end;

            text-align: center;
        }
    
        h1 {
            font-family: 'Poppins';
            font-weight: 900;
            color: $primaryTextColor;
        }
    
        h5 {
            margin: toRem(5) 0;
            font-size: toRem(24);
            font-family: 'Poppins';
            font-weight: 500;
            line-height: normal;
            color: $primaryTextColor;
        }

        button {
            width: auto;
            height: auto;
            padding: toRem(10) toRem(20);
            margin-top: toRem(40);
        
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: $size_h5;
            line-height: normal;
            align-self: center;
    
            // border: toRem(2) dotted $primaryTextColor;
            border: none;
            border-radius: toRem(100);
            color: $primaryTextColor;
            background-color: #ffffff;  
    
            -webkit-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            -moz-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            -ms-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            -o-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
            cursor: pointer;
        }
    }
}

.title {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;

    h3.outline {
        position: absolute; 
        color: #bbbbbb;
        top: 4px;
        left: 4px;
        
        // -webkit-text-stroke-width: 3px;
        // -webkit-text-stroke-color:#bbbbbb;
        
        // text-shadow: 1px -1px 0 $primaryTextColor, 
        //             -1px 1px 0 $primaryTextColor, 
        //             1px 1px 0 $primaryTextColor, 
        //             -1px -1px 0 $primaryTextColor;
    }

    h3.fill {
        color: $primaryTextColor;
        z-index: 1;
        // top: 0;
        // left: 0;
    }

    h3 {
        margin-bottom: 0;
        margin-top: 0;

        // font-family: 'Host Grotesk';
        font-size: toRem(72);
        font-weight: 700;
    }
}



div.resume {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;

    .container {
        width: 50%;
        margin: auto;
        margin-top: 10rem;

        .row {
            flex-direction: row;
        }

        .section {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: toRem(128);

            h3 {
                margin: 0;
                margin-bottom: toRem(16);
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: toRem(36);   
    
                @media screen and (min-width: 768px){
                    font-size: toRem(48);     
                }
            }

            h4 {
                margin: 0;
                margin-bottom: toRem(8);
                font-family: 'Poppins', serif;
                font-weight: 600;
                font-size: toRem(22);

                @media screen and (min-width: 768px){
                    font-size: toRem(24);          
                }
            }

            h5 {
                margin-top: 0;
                margin-bottom: toRem(8);
                font-family: 'Poppins', serif;
                font-weight: 600;
            }

            p {
                margin-top: 0;
                margin-bottom: toRem(16);
                font-family: 'Host Grotesk';
                font-size: toRem(17);

                @media screen and (min-width: 768px){
                    font-size: toRem(19);          
                }

                .period {
                    font-size: toRem(15);

                    @media screen and (min-width: 768px){
                        font-size: toRem(19);
                    }
                }
            }

            .experienceHeader {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: baseline;

                &.mobile {
                    display: block;
                }

                .pdf {
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: toRem(16);

                    &.mobile {
                        border-radius: toRem(24);
                        border-color: #888888;
                        border: toRem(2) solid #cccccc;
                        margin-bottom: toRem(64);
                        padding: toRem(8) toRem(8);
                        padding-right: toRem(24);

                        justify-content: center;
                    }
    
                    a {
                        position: relative;

                        font-family: 'Poppins', serif;
                        font-weight: 500;
                        font-size: toRem(18);
    
                        text-decoration: none;
                        color: $primaryTextColor;

                        img {
                            position: absolute;

                            width: toRem(24);
                            margin-left: toRem(8);
                            transition: margin $transitionTimingCard ease-in-out, transform $transitionTimingCard ease-out;
                        }

                        &:hover {
                            color: #888888;
                            
                            img {
                                transform: rotate(45deg);
                                filter: invert(75%) sepia(0%) saturate(253%) hue-rotate(140deg) brightness(95%) contrast(77%);
                            }
                        }
                    }
                }
            }

            .listing {
                margin-top: toRem(16);
                margin-bottom: toRem(96);

                width: 100%;
                overflow: hidden;

                a {
                    color: #8ca7bF;
                    text-decoration: none;

                    img {
                        width: toRem(14);
                        margin-left: toRem(4);
                    }
                }

                .phrase {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    img {
                        margin: toRem(10) toRem(10);
                        width: toRem(8);
                    }

                    p {
                        margin-left: toRem(10); 
                        line-height: 150%;
                    }
                }
            }


            div.tags {
                position: relative;
                width: 100%;
                // margin-left: auto;
                
                display: flex;
                flex-flow: row wrap;
                row-gap: toRem(8);
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;

                @media screen and (min-width: 768px){
                    margin-top: toRem(8);
                }
    
                div.tagWrapper {
                    border-radius: toRem(48);
                    border: toRem(2) solid #cccccc;
                    padding: toRem(2) toRem(8);
                    margin-right: toRem(8);

                    padding: toRem(8) toRem(12);
                    margin-right: toRem(8);
                    margin-bottom: toRem(4);

                    border-color:#aaaaaa !important;

                    @media screen and (min-width: 768px){    
                        padding: toRem(4) toRem(12);
                        margin-right: toRem(12);
                        margin-bottom: toRem(4);
                    }
                }

                h6 {
                    margin: 0;
                    // padding-right: toRem(10);
    
                    font-family: 'DM Sans';
                    // font-family: 'Host Grotesk';
                    // font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    color: #cccccc;

                    @media screen and (min-width: 768px){
                        font-size: toRem(17);          
                    }
                }
            }  

            .experience .listing {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                gap: toRem(64);

                .left {
                    min-width: 30%;
                }

                .right {
                    max-width: 70%;
                }

                &.mobile {
                    margin-bottom: toRem(96);
                    display: block;
                }
            }
            
            .education {
                margin-top: toRem(16);
                margin-bottom: toRem(40);
                // padding: toRem(24) toRem(24);
                // border-width: toRem(4);
                // border-color: #bbbbbb;
                // border-style: solid;
                // border-radius: toRem(8);

                // width: 100%;
                // background-color: #ffffff;
                overflow: hidden;
            }

            .skills {
                display: flex;
                flex-flow: row wrap;
                // justify-content: space-between;
                align-items: stretch;
                // gap: toRem(32);
                margin-top: toRem(4);
                margin-bottom: toRem(24);

                .listing {
                    width: 100%;
                    min-width: 24%;
                    margin-bottom: toRem(96);

                    display: flex;
                    flex-flow: column;
                    flex-grow: 1;

                    .left {
                        display: flex;
                        flex-flow: column;

                        @media screen and (min-width: 768px){    
                            flex-flow: row wrap;
                            column-gap: toRem(64);
                        }
                    }
                }

                .tags {
                    margin: 0;
                    width: 100%;

                    @media screen and (min-width: 768px){
                        margin-top: toRem(8);
                        width: 50%;
                    }

                    .tagWrapper {
                        padding: toRem(8) toRem(12);
                        margin-right: toRem(8);
                        margin-bottom: toRem(4);
                        border-radius: toRem(24);

                        border-color:#aaaaaa !important;

                        @media screen and (min-width: 768px){    
                            padding: toRem(8) toRem(16);
                            margin-right: toRem(16);
                            margin-bottom: toRem(8);
                        }
                    }

                    h6 {
                        @media screen and (min-width: 768px){
                            font-size: toRem(17);          
                        }
                    }
                }

                p {  
                    font-family: 'Host Grotesk';
                    font-size: toRem(17);
                    line-height: 150%;
                    width: 100%;

                    @media screen and (min-width: 768px){
                        width: 40%;
                        font-size: toRem(19);          
                    }
                }
            }
        }
    }
}



div.about {
    position: relative;
    width: 100%;
    margin: auto;
    background-color: $skyPinkColor;
    overflow: clip;

    h3 {
        margin-left: 12.5%;
    }

    .content {
        width: 75%;
        height: 200vh;

        @media screen and (min-width: 768px){
            height: 200vh;
        }

        margin: auto;
        background-color: rgba(255, 255, 255, 0);
        overflow: visible;

        .prof {
            position: relative;
            max-width: 30%;

            img {
                width: 100%;
            }

            .actual {
                position: absolute;
                width: 100%;
                z-index: 2;

                border-radius: toRem(32);
            }

            .outline {
                position: absolute; 
                left: -1rem;
                top: -1rem;
                width: 100%;

                border-width: toRem(4);
                border-color: $primaryTextColor;
                border-style: solid;
                border-radius: toRem(32);

                img {
                    opacity: 0;
                }
            }

            .fill {
                margin-left: 1rem;
                margin-top: 1rem;
                width: 100%;
                border-radius: toRem(32);

                background-color: #aaaaaa77;

                img {
                    opacity: 0;
                }
            }
        }

        .text {
            position: relative;
            margin-left: 12.5%;
            max-width: 50%;

            p {
                font-family: 'Host Grotesk';
                font-size: toRem(17);
                line-height: 150%;

                @media screen and (min-width: 768px){
                    font-size: toRem(19);          
                }
            }
        }

        .title {
            margin-bottom: 0;

            h3 {
                margin-left: 0;
                margin-top: 0;
            }
        }
    }

    .background {
        position: absolute;
        bottom: 0;
        height: 50vh;
        margin-bottom: 0;
        overflow-y: visible;
        overflow-x: clip;
    }
}